/**
 * Document-wide CSS variables.
 */
body {
  // Overridden by document where a featured image is available on the post.
  --featured-image: #{$default_featured_image};
}

/**
 * Site header
 */
.site-header {
  background-color: $header_color;
  padding-bottom: 1rem;

  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  .site-description {
      display: inline-block;
      flex: auto;
      flex-shrink: 1;
      h1 {
          margin-bottom: 0;
          a.site-title {
            @include relative-font-size(1.625);
            font-weight: 300;
            letter-spacing: -1px;
            margin-bottom: 0;
            
            padding-right: 45px;
            text-decoration: none;
            &,
            &:visited {
              color: $site-title-color;
            }
          }
      }
      p {
          margin-bottom: 0;
      }
  }
  .links {
    flex: auto;
    flex-direction: column;
    text-align: right;
  }
  .social-links {
      margin-bottom: 1rem;
      .social-media-list {
        margin-right: 0;
        display: inline-block;
        li {
          a {
            padding: 0;
            border: none;
          }
          float: right;
          margin: 0;
        }
      }
  }
  .site-nav {
      position: relative;
      // display: inline-block;

      .nav-trigger {
        display: none;
      }
    
      .menu-icon {
        width: 18px;
        height: 15px;
        line-height: 0;
        padding-top: 10px;
        text-align: center;
    
        > svg path {
          fill: $border-color-03;
        }
      }
    
      label[for="nav-trigger"] {
        display: inline-block;
        cursor: pointer;
      }
    
      input ~ .trigger {
        clear: both;
        display: none;
      }
    
      input:checked ~ .trigger {
        display: block;
        position: absolute;
        right: 0;
        background-color: $background-color;
        border: 1px solid $border-color-01;
        padding-bottom: 5px;
      }
    
      .page-link {
        color: $text-color;
        line-height: $base-line-height;
        display: block;
        padding: 5px 10px;
    
        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
          margin-right: 0;
        }
        margin-left: 20px;
      }
      
      @include media-query($on-palm) {
        position: static;
        border: none;
        background-color: inherit;
    
        label[for="nav-trigger"] {
          display: none;
        }
    
        .menu-icon {
          display: none;
        }
    
        input ~ .trigger {
          display: block;
        }
    
        .page-link {
          display: inline;
          padding: 0;
    
          &:not(:last-child) {
            margin-right: 20px;
          }
          margin-left: auto;
        }
      }
  }
}


/**
 * Pagination
 */
ul.pager {
  text-align: center; 
  list-style: none; 
}
ul.pager li {
  display: inline;
  border: 1px solid $border-color-01;
  padding: 10px; 
  margin: 5px;
}

/**
 * Site footer
 */
.site-footer {
  background-color: $header_color;
  border-top: 1px solid $border-color-01;
  padding: $spacing-unit 0;
  
  .footer-columns {
    @include relative-font-size(0.9375);
    color: $brand-color;
    display: flex;
    
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    @include media-query($on-palm) {
      flex-direction: row;
    }
  }

  .feed-subscribe .svg-icon {
    padding: 5px 5px 2px 0
  }
}


/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1 0 auto;

  .page-heading {
    @include relative-font-size(2);
  }
  
  // Style post excerpts
  .post-list {
    margin-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  
    > li {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: rgba(0,0,0,0.6);
        border-radius: 2px;
        margin-bottom: $spacing-unit;
        min-height: 200px;
        width: 100%;
        
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        
        > header {
            background-image:   linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0)),
                            var(--featured-image);
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: flex-end;
            padding: 1rem;

            .post-meta {
              font-size: $small-font-size;
              color: white;
            }
            .post-link {
                text-decoration: none;
                font-weight: 600;
                color: white;
            }
        }

        > div.excerpt {
            padding: 1rem;
            > div.read-more {
                text-align: right;
                > a {
                    text-transform:  uppercase;
                    text-decoration: none;
                    padding: 0.3rem;
                    font-weight: 600;
                }
            }
        }
    }
    @include media-query($on-laptop) {
      > li {
        width: calc(50% - 0.5 * #{$spacing-unit});
      }
    }
    > li:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }
}

/**
 * Posts
 */

 article {

  header {
    margin-bottom: $spacing-unit;
    // Style post headers with the featured image.
    background-image:   linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1.0)),
                        var(--featured-image);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
    height: 300px;
    font-weight: 600;
    color: white;

    .post-meta {
      font-size: $small-font-size;
      color: $text-color;
    }
    h1 {
      letter-spacing: -1px;
      line-height: 1.15;
      @include relative-font-size(1.6);
      @include media-query($on-palm) {
        @include relative-font-size(2);
      }
      @include media-query($on-laptop) {
        @include relative-font-size(2.6);
      }
    }
  }

  
  .post-content {
    margin-bottom: $spacing-unit;

    
    h1, h2, h3 { margin-top: $spacing-unit * 1.5 }
    h4, h5, h6 { margin-top: $spacing-unit }
    
    h1 {
      @include relative-font-size(2.625);
      letter-spacing: -1px;
      line-height: 1.15;
      @include media-query($on-laptop) {
        @include relative-font-size(2.625);
      }
    }

    h2 {
      @include relative-font-size(1.75);
      @include media-query($on-laptop) {
        @include relative-font-size(2);
      }
    }

    h3 {
      @include relative-font-size(1.375);
      @include media-query($on-laptop) {
        @include relative-font-size(1.625);
      }
    }

    h4 {
      @include relative-font-size(1.25);
    }

    h5 {
      @include relative-font-size(1.125);
    }

    h6 {
      @include relative-font-size(1.0625);
    }
  }
}

/**
 * Code highlighting
 */
// Disable alternate row coloring and borders for tables created by highlight.js line numbers.
table.hljs-ln {
  border: none;
  margin-bottom: 0px;
  th {
    border: none;
  }
  td {
    border: none;
  }
  td.hljs-ln-numbers {
      padding-right: 1rem;
  }
  tr:last-child {
    padding-bottom: 14px;
  }
}

.hljs {
  ::-webkit-scrollbar {
      height: 6px;
      background: #bbbbbb63;
  }
  ::-webkit-scrollbar-thumb:horizontal{
      background: #bbbbbb;
      border-radius: 2px;
  }
}
/**
 * Captioned images / figures
 */
 figure.captioned {
  width: 100%; 
  text-align: center;
  background-color: scale-color($background-color, $lightness: -20%);
  
  img {
      width: 100%;
  }
  figcaption {
      font-size: $small-font-size;
      font-weight: 600;
      padding: 0.5rem;
      display: block;
  }
}


blockquote.right-third,
figure.right-third,
img.right-third {
  width: 100%;
  
  @include media-query($on-palm) {
    width: 50%;
    margin-left: 1rem;
  }

  @include media-query($on-laptop) {
    width: 33%;
    margin-left: 1rem;
  }
  float: right; 
}

blockquote.right-half,
figure.right-half,
img.right-half {
  
  @include media-query($on-palm) {
    width: 50%;
    margin-left: 1rem;
  }
  float: right; 
}
blockquote.left-half,
figure.left-half,
img.left-half {
  width: 100%; 
  @include media-query($on-palm) {
    width: 50%;
  }
  margin-right: 1rem;
  float: left; 
}
.right {
  float: right;
}
.left {
  float: left;
}
.center {
  margin: 0 auto;
}
.full-width {
  width: 100%;
}
.clear-all {
  clear: both;
}

table {
  clear: both;
}

/**
 * Social media links
 */

.social-media-list {
  list-style: none;
  display: table;
  margin: 0 auto;
  li {
    float: left;
    margin: 5px 10px 5px 0;
    &:last-of-type { margin-right: 0 }
    a {
      display: block;
      padding: calc($spacing-unit / 4);
      border: 1px solid $border-color-01;
      &:hover { border-color: $border-color-02 }
    }
  }
}

blockquote.callout {
  font-size: $base-font-size * 0.9;
  font-style: normal;
  background-color: scale-color($background-color, $lightness: -20%);
  padding: 1rem;
  color: #d0c58a;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed;
}
blockquote.callout:not(.no-icon)::before { 
  background-image: url(/images/information_icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  content: " ";
  margin-right: 1rem;
  margin-bottom: 1rem;
  @include media-query($on-palm) {
    width: 128px;
  }
}

blockquote.archived-comment {
  font-size: $base-font-size * 0.9;
  font-style: normal;
  background-color: scale-color($background-color, $lightness: -20%);
  padding: 1rem;
  color: #7d7d7d;
  border: 1px solid;
}
// Highlight.js override
.hljs-comment {
  color: #928c6d !important;
}
